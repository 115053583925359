import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomFeedbackPopupComponent } from './custom-feedback-popup/custom-feedback-popup.component';
import { FeedbackData } from '../types/feedback-data.type';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private snackBar: MatSnackBar) {}

  showFeedbackPopup(data: FeedbackData, duration: number) {
    this.snackBar.openFromComponent(CustomFeedbackPopupComponent, {
      data,
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
