<span class="font-mada block text-center text-lg">
  <ng-container *ngIf="!data.isFailed; else failureMessage">
    Your {{ data.assigneeTitle }}
    <a
      class="!font-mada cursor-pointer border-b-2 border-white font-normal"
      (click)="goToAssignee()"
      >"{{ data.assigneeName }}"</a
    >
    has been <span class="font-medium !text-lime-500">successfully</span>
    {{ data.isMoved ? 'moved' : 'added' }}
    to the project
    <a
      class="!font-mada cursor-pointer border-b-2 border-white font-normal"
      (click)="goToTarget()"
      >"{{ data.targetName }}"</a
    >
  </ng-container>

  <ng-template #failureMessage>
    <span class="font-bold !text-red-400">Failed</span> to
    {{ data.isMoved ? 'move' : 'add' }} your "<a
      (click)="goToAssignee()"
      class="!font-mada cursor-pointer border-b-2 border-white font-normal"
      >{{ data.assigneeName }}</a
    >" to the project
    <a
      class="!font-mada cursor-pointer border-b-2 border-white font-normal"
      (click)="goToTarget()"
      >{{ data.targetName }}</a
    >
    Please try again
  </ng-template>
</span>
