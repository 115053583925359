import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FeedbackData } from '@codenteam/ui/types/feedback-data.type';

@Component({
  selector: 'codenteam-custom-feedback-popup',
  templateUrl: './custom-feedback-popup.component.html',
  styleUrl: './custom-feedback-popup.component.css',
})
export class CustomFeedbackPopupComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: FeedbackData,
    private router: Router
  ) {}

  goToTarget() {
    this.router.navigate(this.data.targetLink);
  }

  goToAssignee() {
    this.router.navigate(this.data.assigneeLink);
  }
}
